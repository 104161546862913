<template>
  <v-content>
    <router-view style="background-color: #f5f6fd; min-height: 100vh" />

    <!-- <dashboard-core-footer /> -->
  </v-content>
</template>

<script>
export default {
  name: "DashboardCoreView",

  components: {
    //  DashboardCoreFooter: () => import('./Footer'),
  },
};
</script>
